import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default ({ store }, inject) => {
  const bugsnagClient = Bugsnag.start({
    apiKey: '5852d7fbbc139b253b34e8b9fdca77b8',
    appType: 'app',
    appVersion: process.env.NUXT_ENV_APP_VERSION || 'unknown',
    plugins: [new BugsnagPluginVue(Vue)],
  })

  // vm.$bugsnagでアクセスできるようにする
  inject('bugsnag', bugsnagClient)

  // Vuexのuserが変更されたらBugsnagのプロフィールを変更
  store.watch(
    (state) => {
      return state.user
    },
    (user) => {
      if (user) {
        bugsnagClient.setUser(user.id, user.email, user.name)
        bugsnagClient.addMetadata('user', { role: user.role })
      }
    },
    { deep: true }
  )
}
