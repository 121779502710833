const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['intercom-update'] = require('../middleware/intercom-update.js')
middleware['intercom-update'] = middleware['intercom-update'].default || middleware['intercom-update']

middleware['restrict-viewer'] = require('../middleware/restrict-viewer.js')
middleware['restrict-viewer'] = middleware['restrict-viewer'].default || middleware['restrict-viewer']

export default middleware
