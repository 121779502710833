export const state = () => {
  return {
    accountId: null,
    name: null,
    email: null,
    role: null,
    reauthenticating: false,
  }
}

export const mutations = {
  SET_USER(state, value) {
    state.accountId = value.accountId
    state.name = value.name
    state.email = value.email
    state.role = value.role
  },
  SET_REAUTHENTICATING(state, value) {
    state.reauthenticating = value
  },
}

export const actions = {
  async update({ commit }, { refresh = false } = {}) {
    if (refresh) await this.$auth0.getTokenSilently({ cacheMode: 'off' })
    const user = await this.$auth0.getUser()
    const appMetadata = user['https://www.fondesk.jp/app_metadata'] || {}

    let profile
    const profiles = appMetadata.fondesk?.profiles
    const selectedAccountId = localStorage.getItem('selectedAccountId')

    // アカウントを追加するために無指定
    if (selectedAccountId === '') {
      profile = null
    }
    // ログインしたことがない
    else if (selectedAccountId === null) {
      profile = profiles?.[0] // プロファイル配列の最初のアカウントを選択、なければ無指定
    }
    // 前回ログインしたときのアカウントIDがわかる
    else {
      profile =
        profiles?.find((p) => p.accountId == selectedAccountId) ||
        profiles?.[0] ||
        null
      if (!profile) localStorage.removeItem('selectedAccountId')
    }

    commit('SET_USER', {
      accountId: profile?.accountId,
      name: user.name,
      email: user.email,
      role: profile?.role,
    })
  },
}

export const getters = {
  is: (state) => (role) => state.role === role,
  isOwner: (state) => state.role === 'owner',
}
