import { compare } from 'compare-versions'

export const state = () => {
  return {
    apiVersion: null,
  }
}

export const mutations = {
  SET_API_VERSION(state, value) {
    state.apiVersion = value
  },
}

export const getters = {
  updateRequired(state) {
    try {
      return compare(process.env.NUXT_ENV_APP_VERSION, state.apiVersion, '<')
    } catch (_error) {
      return false
    }
  },
}
