import Vue from 'vue'

import Vuetify from 'vuetify';

Vue.use(Vuetify, {
  "theme": {
    "primary": "#3894FF",
    "secondary": "#424242",
    "accent": "#82B1FF",
    "error": "#FF5252",
    "info": "#2196F3",
    "success": "#4CAF50",
    "warning": "#FFC107"
  }
})
