/* globals Intercom, intercomSettings */

export default async ({ app, route, store, redirect }) => {
  // 認証状態をチェックして必要なら再認証
  const user = await app.$auth0
    .getUser()
    .then((user) => user || reauthenticate({ app, store, route }))

  const appMetadata = user['https://www.fondesk.jp/app_metadata'] || {}

  // Storeの情報を更新
  await store.dispatch('user/update')

  if (route.path !== '/setup/') {
    // アカウントに紐付いていない場合はチェックページへ
    if (!store.state.user.accountId) {
      return redirect('/account/check')
    }

    // セットアップ完了が確認できない場合はセットアップウィザードへ
    const setupComplete = await fetchSetupStatus({ app })
    if (setupComplete === 'false') return redirect('/setup/')
  }

  // Intercomにユーザー情報をセット
  await bootIntercom({ app, store }, appMetadata.fondesk)
}

/**
 * 再認証
 */
async function reauthenticate({ app, store, route }) {
  try {
    store.commit('user/SET_REAUTHENTICATING', true)
    await app.$auth0.getTokenSilently()
    store.commit('user/SET_REAUTHENTICATING', false)
    const user = await app.$auth0.getUser()
    if (!user) throw new Error()
    return user
  } catch (_error) {
    if (!navigator.cookieEnabled) {
      const message =
        'Cookieが無効になっているためページを正しく表示できません。お使いの環境の設定をご確認ください。'
      return alert(message)
    }
    if (typeof Intercom === 'function') Intercom('shutdown')
    const filteredRoute = Object.keys(route)
      .filter((key) => ['path', 'params', 'query'].includes(key))
      .reduce((obj, key) => {
        obj[key] = route[key]
        return obj
      }, {})
    app.$auth0.loginWithRedirect({ appState: { route: filteredRoute } })
    return new Promise((_resolve) => {}) // 解決しないPromiseを返してレンダリングを防ぐ
  }
}

/**
 * セットアップの完了状態をチェック
 */
async function fetchSetupStatus({ app }) {
  // ローカルストレージから状態を取得
  let setupComplete = window.localStorage.getItem('setupComplete')

  // 見つからない場合はAPIから状態を取得し、ローカルストレージに保存
  if (!setupComplete) {
    const taskDefinition = await app.$axios
      .$get(`/v2/task-definitions/self`)
      .catch(() => {})
    if (taskDefinition) {
      setupComplete =
        taskDefinition.options && taskDefinition.options.contactKana
          ? 'true'
          : 'false'
    } else {
      setupComplete = 'false'
    }
    window.localStorage.setItem('setupComplete', setupComplete)
  }

  return setupComplete
}

/**
 * Intercomにユーザー情報をセット
 */
async function bootIntercom({ app }, { userId } = {}) {
  if (typeof Intercom === 'function' && intercomSettings) {
    // Local StorageからIntercomの状態を取得
    const state = JSON.parse(
      window.localStorage['intercom-state-' + intercomSettings.app_id] || '{}'
    )
    const role = state && state.user && state.user.role

    // Intercomのユーザー種別がuserではない場合、ユーザー情報を渡してリブート
    if (role !== 'user' && userId) {
      let userHash = window.localStorage.getItem('intercomUserHash' + userId)
      if (!userHash) {
        userHash = await app.$axios
          .$get(`/v2/users/me/intercom-hash`)
          .catch(() => {})
        if (!userHash) return
        window.localStorage.setItem('intercomUserHash' + userId, userHash)
      }
      Intercom('boot', {
        user_id: userId,
        user_hash: userHash,
      })
    }
  }
}
