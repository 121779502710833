<template>
  <div>
    <div v-if="error.statusCode === 404" class="text-xs-center">
      ページが見つかりません
    </div>
    <div v-else-if="error.statusCode === 403" class="text-xs-center">
      このページを見ることはできません
    </div>
    <div v-else class="text-xs-center">エラーが発生しました</div>
  </div>
</template>

<script>
export default {
  layout: 'plain',
  props: { error: { type: Object, default: null } },
}
</script>
