/* globals Intercom */

import Vue from 'vue'

Vue.mixin({
  methods: {
    $intercom() {
      if (typeof Intercom === 'function') {
        Intercom(...arguments)
      } else if (arguments[0] === 'show') {
        location.href = 'mailto:support@fondesk.jp'
      }
    },
  },
})

export default () => {
  const appIds = {
    'www.fondesk.jp': 't1xiwdfz',
    'stg.fondesk.jp': 'ci36i724',
    'dev.fondesk.jp': 'jkbqsej0',
  }
  var appId =
    appIds[location.hostname] ||
    process.env.NUXT_ENV_INTERCOM_APP_ID ||
    'qe7njctt'
  window.intercomSettings = {
    app_id: appId,
    custom_launcher_selector: '#intercom-launcher',
  }
  var ic = window.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', window.intercomSettings)
  } else {
    var i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    window.Intercom = i

    var load = function () {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = 'https://widget.intercom.io/widget/' + appId
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(script, x)
    }

    if (document.readyState === 'complete') {
      load()
    } else if (window.attachEvent) {
      window.attachEvent('onload', load)
    } else {
      window.addEventListener('load', load, false)
    }
  }

  const button = document.createElement('div')
  button.setAttribute('id', 'intercom-launcher')
  button.setAttribute('class', 'app')
  const image = document.createElement('img')
  image.src = '/assets/images/intercom-button-pelikan.svg'
  button.appendChild(image)
  const body = document.getElementsByTagName('body')[0]
  body.appendChild(button)
  window.Intercom('onShow', () => {
    button.style.display = 'none'
  })
  window.Intercom('onHide', () => {
    button.style.display = 'block'
  })
  const loadingCheckInterval = setInterval(() => {
    if (window.Intercom.booted) {
      button.setAttribute('class', 'app booted')
      clearInterval(loadingCheckInterval)
    }
  }, 1000)
}
