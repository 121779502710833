<template>
  <svg
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g>
      <g>
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          :fill="fill"
          d="M19.28 8.6l-.7-1.21-1.27.51-1.06.43-.91-.7a5.09 5.09 0 0 0-1.23-.71l-1.06-.43-.16-1.13L12.7 4h-1.4l-.19 1.35L11 6.48l-1.06.44a5.86 5.86 0 0 0-1.25.73l-.9.68-1-.42-1.37-.52-.7 1.21 1.08.84.89.7-.14 1.13v1.46l.14 1.13-.89.7-1.08.84.7 1.21 1.27-.51 1.06-.43.91.7a5.09 5.09 0 0 0 1.23.71l1.06.43.16 1.13.19 1.36h1.39l.19-1.35.12-1.13 1.06-.43a5.86 5.86 0 0 0 1.25-.73l.9-.68 1 .42 1.27.51.7-1.21-1.08-.84-.89-.7.14-1.13a5.34 5.34 0 0 0 .05-.73c0-.21 0-.43-.05-.73l-.14-1.13.89-.7zM12 16a4 4 0 1 1 4-4 4 4 0 0 1-4 4z"
          style="isolation: isolate"
        />
        <path
          d="M19.43 13a7.79 7.79 0 0 0 .07-1 7.79 7.79 0 0 0-.07-1l2.11-1.65a.5.5 0 0 0 .12-.64l-2-3.46a.5.5 0 0 0-.44-.25.47.47 0 0 0-.17 0l-2.49 1a7.31 7.31 0 0 0-1.69-1l-.38-2.65A.49.49 0 0 0 14 2h-4a.49.49 0 0 0-.49.42l-.38 2.65a7.68 7.68 0 0 0-1.69 1L5 5.05 4.77 5a.5.5 0 0 0-.43.25l-2 3.46a.49.49 0 0 0 .12.64L4.57 11a7.93 7.93 0 0 0-.07 1 7.93 7.93 0 0 0 .07 1l-2.11 1.63a.5.5 0 0 0-.12.64l2 3.46a.5.5 0 0 0 .44.25A.47.47 0 0 0 5 19l2.49-1a7.31 7.31 0 0 0 1.69 1l.38 2.65A.49.49 0 0 0 10 22h4a.49.49 0 0 0 .49-.42l.38-2.65a7.68 7.68 0 0 0 1.69-1l2.49 1a.57.57 0 0 0 .18 0 .5.5 0 0 0 .43-.25l2-3.46a.5.5 0 0 0-.12-.64zm-2-1.71a5.34 5.34 0 0 1 .05.73c0 .21 0 .43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1-.42-.9.68a5.86 5.86 0 0 1-1.25.73l-1.06.43-.16 1.13L12.7 20h-1.4l-.19-1.35-.11-1.13-1.06-.43a5.67 5.67 0 0 1-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13V12v-.73l.14-1.13-.89-.7-1.13-.84.7-1.21 1.27.51 1 .42.9-.68a5.86 5.86 0 0 1 1.25-.73l1.06-.43.16-1.13L11.3 4h1.39l.19 1.35.12 1.13 1.06.43a5.67 5.67 0 0 1 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7zM12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    fill: {
      type: String,
      default: '#FCD64D',
    },
  },
}
</script>
