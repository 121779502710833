/* globals Intercom */

import axios from 'axios'
const CancelToken = axios.CancelToken

export default ({ $axios, app, route, store }) => {
  $axios.onRequest(async (config) => {
    // IE11 キャッシュ問題対策
    // https://gist.github.com/keyama4/e33241bf63fe2a0d801d4efb0a8c1932
    // ※GET以外にも適用すると不具合が出る場合があるので、GETリクエストに限定
    if (typeof config.params === 'undefined') {
      config.params = {}
    }
    if (typeof config.params === 'object' && config.method === 'get') {
      if (
        typeof URLSearchParams === 'function' &&
        config.params instanceof URLSearchParams
      )
        config.params.append('_', Date.now())
      else config.params._ = Date.now()
    }

    // リクエストヘッダにクライアントバージョンを追加
    config.headers['x-fondesk-client-version'] = process.env.NUXT_ENV_APP_VERSION

    // アクセストークンをヘッダに付与
    // 認証セッションが切れている場合はリクエストを中断して再ログイン
    if (!config.public) {
      try {
        const accessToken = await app.$auth0.getTokenSilently()
        config.headers.Authorization = `Bearer ${accessToken}`
        if (store.state.user.accountId) {
          config.headers['x-fondesk-account-id'] = store.state.user.accountId
        }
      } catch (_error) {
        if (navigator.cookieEnabled) {
          if (typeof Intercom === 'function') Intercom('shutdown')
          const filteredRoute = Object.keys(route)
            .filter((key) => ['path', 'params', 'query'].includes(key))
            .reduce((obj, key) => {
              obj[key] = route[key]
              return obj
            }, {})
          app.$auth0.loginWithRedirect({ appState: { route: filteredRoute } })
        }
        config.cancelToken = new CancelToken((cancel) =>
          cancel('Request canceled')
        )
      }
    }

    return config
  })

  $axios.onResponse((res) => {
    store.commit('client/SET_API_VERSION', res.headers['x-api-version'])
  })

  $axios.onError((error) => {
    if (!error.response) return

    // レスポンスがバイナリの場合に内容をデコード
    if (error.response.data instanceof ArrayBuffer) {
      error.response.data = JSON.parse(decodeError(error.response.data))
    }
  })
}

function decodeError(data) {
  if ('TextDecoder' in window) {
    // Decode as UTF-8
    const decoder = new TextDecoder('utf8')
    return decoder.decode(new DataView(data))
  } else {
    // Fallback decode as ASCII
    return String.fromCharCode.apply(null, new Uint8Array(data))
  }
}
