import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1e782126 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _fd55cb56 = () => interopDefault(import('../pages/calls/index.vue' /* webpackChunkName: "pages/calls/index" */))
const _67a35aa4 = () => interopDefault(import('../pages/holidays.vue' /* webpackChunkName: "pages/holidays" */))
const _6fcefe0c = () => interopDefault(import('../pages/overview.vue' /* webpackChunkName: "pages/overview" */))
const _690ecdb9 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _a141a40c = () => interopDefault(import('../pages/setup.vue' /* webpackChunkName: "pages/setup" */))
const _126a9e64 = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _e1eacf0c = () => interopDefault(import('../pages/account/callback.vue' /* webpackChunkName: "pages/account/callback" */))
const _6e63e76f = () => interopDefault(import('../pages/account/cancel.vue' /* webpackChunkName: "pages/account/cancel" */))
const _38a7a0a3 = () => interopDefault(import('../pages/account/check.vue' /* webpackChunkName: "pages/account/check" */))
const _a0d6cdbe = () => interopDefault(import('../pages/account/email-verification.vue' /* webpackChunkName: "pages/account/email-verification" */))
const _0a499fa9 = () => interopDefault(import('../pages/account/export.vue' /* webpackChunkName: "pages/account/export" */))
const _048e928e = () => interopDefault(import('../pages/account/invitation.vue' /* webpackChunkName: "pages/account/invitation" */))
const _4d673aca = () => interopDefault(import('../pages/account/invoices.vue' /* webpackChunkName: "pages/account/invoices" */))
const _2f66dc2f = () => interopDefault(import('../pages/account/link.vue' /* webpackChunkName: "pages/account/link" */))
const _8b28daf8 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _01ac61ff = () => interopDefault(import('../pages/account/login-error.vue' /* webpackChunkName: "pages/account/login-error" */))
const _629609e1 = () => interopDefault(import('../pages/account/payment.vue' /* webpackChunkName: "pages/account/payment" */))
const _6e044ff8 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _413ea9e6 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _2dc2f9ae = () => interopDefault(import('../pages/account/switch.vue' /* webpackChunkName: "pages/account/switch" */))
const _f676f5dc = () => interopDefault(import('../pages/account/team.vue' /* webpackChunkName: "pages/account/team" */))
const _4f527100 = () => interopDefault(import('../pages/account/user.vue' /* webpackChunkName: "pages/account/user" */))
const _bc721abe = () => interopDefault(import('../pages/settings/blocking/index.vue' /* webpackChunkName: "pages/settings/blocking/index" */))
const _64b41382 = () => interopDefault(import('../pages/settings/dictionary.vue' /* webpackChunkName: "pages/settings/dictionary" */))
const _d4c67b0c = () => interopDefault(import('../pages/settings/information-card.vue' /* webpackChunkName: "pages/settings/information-card" */))
const _f36d29f6 = () => interopDefault(import('../pages/settings/notification-rule.vue' /* webpackChunkName: "pages/settings/notification-rule" */))
const _9ad6ed5a = () => interopDefault(import('../pages/settings/processing-status.vue' /* webpackChunkName: "pages/settings/processing-status" */))
const _4e3e5a4e = () => interopDefault(import('../pages/settings/sensitive-words.vue' /* webpackChunkName: "pages/settings/sensitive-words" */))
const _4f02f49e = () => interopDefault(import('../pages/settings/verify-email.vue' /* webpackChunkName: "pages/settings/verify-email" */))
const _a0b8095a = () => interopDefault(import('../pages/settings/voice-message.vue' /* webpackChunkName: "pages/settings/voice-message" */))
const _3e23b11f = () => interopDefault(import('../pages/settings/blocking/list.vue' /* webpackChunkName: "pages/settings/blocking/list" */))
const _55d5d69c = () => interopDefault(import('../pages/settings/notifications/chatwork/index.vue' /* webpackChunkName: "pages/settings/notifications/chatwork/index" */))
const _93c06342 = () => interopDefault(import('../pages/settings/notifications/email/index.vue' /* webpackChunkName: "pages/settings/notifications/email/index" */))
const _12eff32f = () => interopDefault(import('../pages/settings/notifications/google-chat/index.vue' /* webpackChunkName: "pages/settings/notifications/google-chat/index" */))
const _aadd5b32 = () => interopDefault(import('../pages/settings/notifications/line/index.vue' /* webpackChunkName: "pages/settings/notifications/line/index" */))
const _2ea997f0 = () => interopDefault(import('../pages/settings/notifications/line-works-app/index.vue' /* webpackChunkName: "pages/settings/notifications/line-works-app/index" */))
const _5f6ad1cc = () => interopDefault(import('../pages/settings/notifications/microsoft-teams/index.vue' /* webpackChunkName: "pages/settings/notifications/microsoft-teams/index" */))
const _09997d5a = () => interopDefault(import('../pages/settings/notifications/slack/index.vue' /* webpackChunkName: "pages/settings/notifications/slack/index" */))
const _16595cc0 = () => interopDefault(import('../pages/settings/notifications/teams-workflows/index.vue' /* webpackChunkName: "pages/settings/notifications/teams-workflows/index" */))
const _225ce9cf = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5ae0d004 = () => interopDefault(import('../pages/settings/notifications/chatwork/_id.vue' /* webpackChunkName: "pages/settings/notifications/chatwork/_id" */))
const _05bbdc87 = () => interopDefault(import('../pages/settings/notifications/email/_id.vue' /* webpackChunkName: "pages/settings/notifications/email/_id" */))
const _3a221552 = () => interopDefault(import('../pages/settings/notifications/google-chat/_id.vue' /* webpackChunkName: "pages/settings/notifications/google-chat/_id" */))
const _329cd658 = () => interopDefault(import('../pages/settings/notifications/line-works-app/_id.vue' /* webpackChunkName: "pages/settings/notifications/line-works-app/_id" */))
const _134ec28f = () => interopDefault(import('../pages/settings/notifications/line/_id.vue' /* webpackChunkName: "pages/settings/notifications/line/_id" */))
const _0830cffc = () => interopDefault(import('../pages/settings/notifications/microsoft-teams/_id.vue' /* webpackChunkName: "pages/settings/notifications/microsoft-teams/_id" */))
const _8991070a = () => interopDefault(import('../pages/settings/notifications/slack/_id.vue' /* webpackChunkName: "pages/settings/notifications/slack/_id" */))
const _0e178f28 = () => interopDefault(import('../pages/settings/notifications/teams-workflows/_id.vue' /* webpackChunkName: "pages/settings/notifications/teams-workflows/_id" */))
const _8e81fa80 = () => interopDefault(import('../pages/calls/_id/index.vue' /* webpackChunkName: "pages/calls/_id/index" */))
const _97ba968e = () => interopDefault(import('../pages/tasks/_id/index.vue' /* webpackChunkName: "pages/tasks/_id/index" */))
const _1ce148fb = () => interopDefault(import('../pages/calls/_id/block.vue' /* webpackChunkName: "pages/calls/_id/block" */))
const _54ea0204 = () => interopDefault(import('../pages/calls/_id/done.vue' /* webpackChunkName: "pages/calls/_id/done" */))
const _1844faf4 = () => interopDefault(import('../pages/tasks/_id/block.vue' /* webpackChunkName: "pages/tasks/_id/block" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/app/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _1e782126,
    name: "account"
  }, {
    path: "/calls",
    component: _fd55cb56,
    name: "calls"
  }, {
    path: "/holidays",
    component: _67a35aa4,
    name: "holidays"
  }, {
    path: "/overview",
    component: _6fcefe0c,
    name: "overview"
  }, {
    path: "/settings",
    component: _690ecdb9,
    name: "settings"
  }, {
    path: "/setup",
    component: _a141a40c,
    name: "setup"
  }, {
    path: "/tasks",
    component: _126a9e64,
    name: "tasks"
  }, {
    path: "/account/callback",
    component: _e1eacf0c,
    name: "account-callback"
  }, {
    path: "/account/cancel",
    component: _6e63e76f,
    name: "account-cancel"
  }, {
    path: "/account/check",
    component: _38a7a0a3,
    name: "account-check"
  }, {
    path: "/account/email-verification",
    component: _a0d6cdbe,
    name: "account-email-verification"
  }, {
    path: "/account/export",
    component: _0a499fa9,
    name: "account-export"
  }, {
    path: "/account/invitation",
    component: _048e928e,
    name: "account-invitation"
  }, {
    path: "/account/invoices",
    component: _4d673aca,
    name: "account-invoices"
  }, {
    path: "/account/link",
    component: _2f66dc2f,
    name: "account-link"
  }, {
    path: "/account/login",
    component: _8b28daf8,
    name: "account-login"
  }, {
    path: "/account/login-error",
    component: _01ac61ff,
    name: "account-login-error"
  }, {
    path: "/account/payment",
    component: _629609e1,
    name: "account-payment"
  }, {
    path: "/account/profile",
    component: _6e044ff8,
    name: "account-profile"
  }, {
    path: "/account/signup",
    component: _413ea9e6,
    name: "account-signup"
  }, {
    path: "/account/switch",
    component: _2dc2f9ae,
    name: "account-switch"
  }, {
    path: "/account/team",
    component: _f676f5dc,
    name: "account-team"
  }, {
    path: "/account/user",
    component: _4f527100,
    name: "account-user"
  }, {
    path: "/settings/blocking",
    component: _bc721abe,
    name: "settings-blocking"
  }, {
    path: "/settings/dictionary",
    component: _64b41382,
    name: "settings-dictionary"
  }, {
    path: "/settings/information-card",
    component: _d4c67b0c,
    name: "settings-information-card"
  }, {
    path: "/settings/notification-rule",
    component: _f36d29f6,
    name: "settings-notification-rule"
  }, {
    path: "/settings/processing-status",
    component: _9ad6ed5a,
    name: "settings-processing-status"
  }, {
    path: "/settings/sensitive-words",
    component: _4e3e5a4e,
    name: "settings-sensitive-words"
  }, {
    path: "/settings/verify-email",
    component: _4f02f49e,
    name: "settings-verify-email"
  }, {
    path: "/settings/voice-message",
    component: _a0b8095a,
    name: "settings-voice-message"
  }, {
    path: "/settings/blocking/list",
    component: _3e23b11f,
    name: "settings-blocking-list"
  }, {
    path: "/settings/notifications/chatwork",
    component: _55d5d69c,
    name: "settings-notifications-chatwork"
  }, {
    path: "/settings/notifications/email",
    component: _93c06342,
    name: "settings-notifications-email"
  }, {
    path: "/settings/notifications/google-chat",
    component: _12eff32f,
    name: "settings-notifications-google-chat"
  }, {
    path: "/settings/notifications/line",
    component: _aadd5b32,
    name: "settings-notifications-line"
  }, {
    path: "/settings/notifications/line-works-app",
    component: _2ea997f0,
    name: "settings-notifications-line-works-app"
  }, {
    path: "/settings/notifications/microsoft-teams",
    component: _5f6ad1cc,
    name: "settings-notifications-microsoft-teams"
  }, {
    path: "/settings/notifications/slack",
    component: _09997d5a,
    name: "settings-notifications-slack"
  }, {
    path: "/settings/notifications/teams-workflows",
    component: _16595cc0,
    name: "settings-notifications-teams-workflows"
  }, {
    path: "/",
    component: _225ce9cf,
    name: "index"
  }, {
    path: "/settings/notifications/chatwork/:id",
    component: _5ae0d004,
    name: "settings-notifications-chatwork-id"
  }, {
    path: "/settings/notifications/email/:id",
    component: _05bbdc87,
    name: "settings-notifications-email-id"
  }, {
    path: "/settings/notifications/google-chat/:id?",
    component: _3a221552,
    name: "settings-notifications-google-chat-id"
  }, {
    path: "/settings/notifications/line-works-app/:id",
    component: _329cd658,
    name: "settings-notifications-line-works-app-id"
  }, {
    path: "/settings/notifications/line/:id",
    component: _134ec28f,
    name: "settings-notifications-line-id"
  }, {
    path: "/settings/notifications/microsoft-teams/:id?",
    component: _0830cffc,
    name: "settings-notifications-microsoft-teams-id"
  }, {
    path: "/settings/notifications/slack/:id",
    component: _8991070a,
    name: "settings-notifications-slack-id"
  }, {
    path: "/settings/notifications/teams-workflows/:id?",
    component: _0e178f28,
    name: "settings-notifications-teams-workflows-id"
  }, {
    path: "/calls/:id",
    component: _8e81fa80,
    name: "calls-id"
  }, {
    path: "/tasks/:id",
    component: _97ba968e,
    name: "tasks-id"
  }, {
    path: "/calls/:id/block",
    component: _1ce148fb,
    name: "calls-id-block"
  }, {
    path: "/calls/:id/done",
    component: _54ea0204,
    name: "calls-id-done"
  }, {
    path: "/tasks/:id/block",
    component: _1844faf4,
    name: "tasks-id-block"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
