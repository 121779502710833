<template>
  <v-app style="overflow: hidden">
    <div class="background" />
    <v-content class="font-rounded">
      <v-container id="app-container">
        <v-layout row>
          <v-flex sm3 class="hidden-sm-and-down">
            <nav id="sidebar" class="px-3">
              <div class="text-xs-center mt-5 mb-4">
                <img
                  src="~/assets/images/fondesk-logo-full-vertical.svg"
                  width="130"
                  alt="fondesk"
                />
              </div>
              <ul class="ml-3 mr-1">
                <li v-if="manageProcessingStatus">
                  <nuxt-link
                    :to="{ path: '/calls', hash: 'inbox' }"
                    :class="{
                      'link-active': pathStarts('/calls', { hash: '#inbox' }),
                    }"
                    style="position: relative"
                  >
                    <icon-inbox
                      :fill="
                        pathStarts('/calls', { hash: '#inbox' })
                          ? 'white'
                          : '#FCD64D'
                      "
                      class="mr-2"
                    />
                    受信トレイ
                    <count-badge
                      v-if="unprocessedCount"
                      :count="unprocessedCount"
                      class="counter-desktop"
                    />
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    to="/calls"
                    :class="{
                      'link-active': pathStarts('/calls', { hash: null }),
                    }"
                  >
                    <icon-all-inbox
                      v-if="manageProcessingStatus"
                      :fill="
                        pathStarts('/calls', { hash: null })
                          ? 'white'
                          : '#FCD64D'
                      "
                      class="mr-2"
                    />
                    <icon-inbox
                      v-else
                      :fill="
                        pathStarts('/calls', { hash: null })
                          ? 'white'
                          : '#FCD64D'
                      "
                      class="mr-2"
                    />
                    {{ manageProcessingStatus ? 'すべての電話' : '受信トレイ' }}
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    to="/settings"
                    :class="{ 'link-active': pathStarts('/settings') }"
                  >
                    <icon-settings
                      :fill="pathStarts('/settings') ? 'white' : '#FCD64D'"
                      class="mr-2"
                    />
                    設定
                  </nuxt-link>
                </li>
                <li>
                  <nuxt-link
                    to="/account"
                    :class="{ 'link-active': pathStarts('/account') }"
                  >
                    <icon-account
                      :fill="pathStarts('/account') ? 'white' : '#FCD64D'"
                      class="mr-2"
                    />
                    アカウント
                  </nuxt-link>
                </li>
              </ul>
            </nav>
          </v-flex>
          <v-flex xs12 md9>
            <nuxt />
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>

    <v-bottom-nav
      :value="true"
      :height="56 + safeAreaInsets.bottom"
      app
      color="white"
      class="hidden-md-and-up font-rounded"
    >
      <v-btn
        v-if="manageProcessingStatus"
        color="black"
        flat
        :to="{ path: '/calls', hash: 'inbox' }"
      >
        <span>受信トレイ</span>
        <v-icon>inbox</v-icon>
        <div v-if="unprocessedCount" class="counter-mobile">
          <count-badge :count="unprocessedCount" />
        </div>
      </v-btn>
      <v-btn
        color="black"
        flat
        to="/calls"
        :exact="!pathStarts('/calls', { hash: null })"
      >
        <span>
          {{ manageProcessingStatus ? 'すべての電話' : '受信トレイ' }}
        </span>
        <v-icon v-if="manageProcessingStatus">inventory_2</v-icon>
        <v-icon v-else>inbox</v-icon>
      </v-btn>
      <v-btn color="black" flat to="/settings">
        <span>設定</span>
        <v-icon>settings</v-icon>
      </v-btn>
      <v-btn color="black" flat to="/account">
        <span>アカウント</span>
        <v-icon>account_circle</v-icon>
      </v-btn>
    </v-bottom-nav>

    <v-snackbar
      :value="$store.state.user.reauthenticating"
      :timeout="0"
      color="cyan"
      top
      left
      auto-height
      class="font-rounded"
    >
      再認証中…
    </v-snackbar>
  </v-app>
</template>

<script>
const safeAreaInsets = require('safe-area-insets')
import IconSettings from '@/components/icons/IconSettings'
import IconAccount from '@/components/icons/IconAccount'
import IconInbox from '@/components/icons/IconInbox'
import IconAllInbox from '@/components/icons/IconAllInbox'
import CountBadge from '@/components/atoms/CountBadge'
export default {
  components: {
    IconSettings,
    IconAccount,
    IconInbox,
    IconAllInbox,
    CountBadge,
  },
  data() {
    return {
      safeAreaInsets: {
        top: safeAreaInsets.top,
        bottom: safeAreaInsets.bottom,
      },
    }
  },
  fetch() {
    return this.$store.dispatch('taskDefinition/getData')
  },
  computed: {
    unprocessedCount() {
      return this.$store.state.taskDefinition.unprocessedCount
    },
    taskDefinition() {
      return this.$store.state.taskDefinition.props
    },
    manageProcessingStatus() {
      return this.$store.getters[
        'taskDefinition/processingStatusManagementEnabled'
      ]
    },
    pathStarts() {
      return (path, option = {}) =>
        this.$route.path.startsWith(path) &&
        !this.$route.query.q &&
        (option.hash === undefined ||
          (option.hash === null && !this.$route.hash) ||
          option.hash === this.$route.hash)
    },
  },
  async created() {
    safeAreaInsets.onChange((style) => {
      this.safeAreaInsets.top = style.top
      this.safeAreaInsets.bottom = style.bottom
    })
  },
}
</script>

<style lang="stylus" scoped>
.application {
  background-color: #ffffff;
}

.background {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  width: 100px;
  height: 100px;
  background: blue;
}

.counter-desktop {
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -9px;
  font-size: 73%;
}

.counter-mobile {
  position: absolute;
  top: -2px;
  right: -5px;
  font-size: 12px;
}

.background::before {
  position: absolute;
  top: -970px;
  left: -230px;
  z-index: 0;
  display: block;
  width: 5000px;
  height: 1000px;
  background-color: #FCD64D;
  content: '';
  transform: rotate(25deg);
  transform-origin: left center;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  -webkit-transform: rotate(25deg);
  -ms-transform: rotate(25deg);

  @media (max-width: 959px) {
    top: -950px;
  }
}

#app-container {
  max-width: 960px;
}

>>> .v-bottom-nav .v-btn__content {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#sidebar {
  ul {
    padding: 0;
    list-style: none;

    li {
      a {
        display: block;
        margin: 3px 0;
        padding: 3px 10px;
        border-radius: 100px;
        color: black;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        &.link-active {
          background-color: #FCD64D;
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  }
}

#intercom-launcher {
  @media only screen and (max-width: 959px) {
    right: 10px;
    bottom: 70px;

    &:hover {
      right: 9px;
      bottom: 69px;
    }
  }
}
</style>
