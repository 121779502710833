<template>
  <v-app>
    <v-content>
      <v-container fill-height>
        <nuxt />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
const viewportUnitsBuggyfill = require('viewport-units-buggyfill')
export default {
  data() {
    return {
      intercomOpened: false,
    }
  },
  created() {
    viewportUnitsBuggyfill.init()
  },
}
</script>

<style lang="stylus" scoped>
.application {
  background-color: #FCD64D;
}
</style>
