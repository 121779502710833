import { createAuth0Client } from '@auth0/auth0-spa-js'

const hostConfig = {
  'www.fondesk.jp': {
    domain: 'auth.fondesk.jp',
    clientId: 'Q49a5xWleP1kyFqUWaX5kdNzqw60kTv4',
  },
  'stg.fondesk.jp': {
    domain: 'fondesk-staging.auth0.com',
    clientId: 'y1TEkt2eM5pkf70aNxf1UKLvAk1cQ5Gk',
  },
}

export default async function (ctx, inject) {
  const config = hostConfig[window.location.host] || {
    domain: process.env.NUXT_ENV_AUTH0_DOMAIN,
    clientId: process.env.NUXT_ENV_AUTH0_CLIENT_ID,
  }
  const $auth = await createAuth0Client({
    domain: config.domain,
    clientId: config.clientId,
    authorizationParams: {
      audience: 'https://www.fondesk.jp/api/',
      redirect_uri: window.location.origin + '/app/account/callback',
    },
  })
  inject('auth0', $auth)
  ctx.$auth0 = $auth
}
