let unprocessedCountTimerId = null

export const state = () => {
  return {
    props: {},
    unprocessedCount: null,
  }
}

export const actions = {
  /**
   * タスク定義を取得
   */
  async getData({ commit, dispatch }) {
    const taskDefinition = await this.$axios.$get(`/v2/task-definitions/self`)
    if (!taskDefinition) {
      window.localStorage.setItem('setupComplete', 'false')
      return this.$router.replace('/setup/')
    }
    commit('SET_DATA', taskDefinition)
    dispatch('initUnprocessedCounter')
  },

  /**
   * タスク定義オプションの変更
   */
  async updateOptions({ dispatch }, value) {
    await this.$axios.$put(`/v2/task-definitions/self/options`, value)
    return await dispatch('getData')
  },

  /**
   * 未対応のタスク件数の自動取得
   */
  initUnprocessedCounter({ state, commit, dispatch }) {
    clearInterval(unprocessedCountTimerId)
    commit('SET_UNPROCESSED_COUNT', null)
    if (state.props.options.manageProcessingStatus) {
      dispatch('getUnprocessedCount')
      unprocessedCountTimerId = setInterval(
        () => dispatch('getUnprocessedCount'),
        5 * 60 * 1000
      )
    }
  },

  /**
   * 未対応のタスク件数を取得
   */
  async getUnprocessedCount({ state, commit }) {
    if (document.visibilityState !== 'visible') return // 非アクティブ時は実行しない
    if (!state.props.options.manageProcessingStatus) return
    const count = await this.$axios.$get(
      `/v2/tasks/unprocessed-count?task_definition_id=${state.props.id}`
    )
    commit('SET_UNPROCESSED_COUNT', count)
  },
}

export const mutations = {
  SET_DATA(state, value) {
    state.props = value
  },
  SET_UNPROCESSED_COUNT(state, value) {
    state.unprocessedCount = value
  },
}

export const getters = {
  processingStatusManagementEnabled: (state) =>
    state.props &&
    state.props.options &&
    state.props.options.manageProcessingStatus,
}
