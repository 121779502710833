<template>
  <div v-if="count" class="counter-badge" :class="{ changed: changed }">
    {{ count > 99 ? '99+' : count }}
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: null,
    },
  },
  data() {
    return { changed: false }
  },
  watch: {
    count() {
      this.changed = true
      setTimeout(() => {
        this.changed = false
      }, 350)
    },
  },
}
</script>

<style lang="stylus" scoped>
.counter-badge {
  background-color: #FF5649;
  color: #ffffff;
  border-radius: 9999px;
  text-align: center;
  line-height: 1;
  padding: 0.3em 0.5em;
  font-weight: 900;

  &.changed {
    animation: 350ms ease-in-out 0s 1 normal none running change;
  }
}

@keyframes change {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.85);
  }

  60% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}
</style>
